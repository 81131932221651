import { useSelector } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import { selectUserRole } from "../../store/slices/global/userSlice";

import { Navbar } from "./Navbar";
import { WorkWithData, WorkWithDataDetails } from "../work-with-data";
import { Monitor } from "../monitor";
import {
  WalkdownManagement,
  WalkdownScheduleEquipment,
} from "../walkdown-management";
import { MaterialsManagement } from "../materials-management";
import { Notification } from "../common/Notification";

import { USER_ROLE } from "../../constants/environment";
import { NoPermission } from "../common/NoPermission";
import {
  EnrichmentManagement,
  EnrichmentScheduleEquipment,
} from "../enrichment-management";

export const Main = () => {
  const userRole = useSelector(selectUserRole);
  const location = useLocation();
  const id = location.pathname.split("/")[2]; // get the id from url path

  const isAdmin = userRole === USER_ROLE.ADMIN;
  const isRestricted = userRole === USER_ROLE.RESTRICTED;
  const isFieldEngineers = userRole === USER_ROLE.FIELD_ENGINEERS;

  const isWorkWithDataEnabled =
    userRole === USER_ROLE.DATA_TEAM ||
    userRole === USER_ROLE.ENRICHMENT_TEAM ||
    userRole === USER_ROLE.SME ||
    userRole === USER_ROLE.WALKDOWN_COORDINATOR ||
    userRole === USER_ROLE.ENRICHMENT_COORDINATOR ||
    isFieldEngineers ||
    isRestricted ||
    isAdmin;

  const isWalkdownManagementEnabled =
    userRole === USER_ROLE.WALKDOWN_COORDINATOR ||
    isFieldEngineers ||
    userRole === USER_ROLE.ENRICHMENT_COORDINATOR ||
    isAdmin;

  const isEnrichmentManagementEnabled =
    userRole === USER_ROLE.ENRICHMENT_TEAM ||
    USER_ROLE.ENRICHMENT_COORDINATOR ||
    isAdmin;

  const isMaterialsManagementEnabled =
    userRole === USER_ROLE.MM_SYSTEM_ANALYST || isAdmin;

  const renderNavigate = () => {
    switch (userRole) {
      case USER_ROLE.ADMIN:
      case USER_ROLE.DATA_TEAM:
      case USER_ROLE.SME:
      case USER_ROLE.RESTRICTED:
        return <Navigate to="/work-with-data" />;
      case USER_ROLE.WALKDOWN_COORDINATOR:
        return <Navigate to="/walkdown-management" />;
      case USER_ROLE.MM_SYSTEM_ANALYST:
        return <Navigate to="/materials-management" />;
      case USER_ROLE.ENRICHMENT_TEAM:
        return <Navigate to="/enrichment-management" />;
      default:
        return <Navigate to="/work-with-data" />;
    }
  };

  return (
    <div className="flex pt-6 pr-10 pb-10 pl-10 flex-col items-start gap-8 w-full">
      <Navbar
        isWorkWithDataEnabled={isWorkWithDataEnabled}
        isWalkdownManagementEnabled={isWalkdownManagementEnabled}
        isMaterialsManagementEnabled={isMaterialsManagementEnabled}
        isEnrichmentManagementEnabled={isEnrichmentManagementEnabled}
      />
      <div className="w-full">
        <Routes>
          <Route index element={renderNavigate()} />
          {isWorkWithDataEnabled && (
            <>
              <Route path="/work-with-data" element={<WorkWithData />} />
              <Route
                path="/work-with-data/:id"
                element={<WorkWithDataDetails id={id} />}
              />
            </>
          )}
          {isWalkdownManagementEnabled && (
            <>
              <Route
                path="/walkdown-management"
                element={<WalkdownManagement />}
              />
              <Route
                path="/walkdown-management/:id"
                element={<WalkdownScheduleEquipment id={id} />}
              />
            </>
          )}
          {isEnrichmentManagementEnabled && (
            <>
              <Route
                path="/enrichment-management"
                element={<EnrichmentManagement />}
              />
              <Route
                path="/enrichment-management/:id"
                element={<EnrichmentScheduleEquipment id={id} />}
              />
            </>
          )}
          {isMaterialsManagementEnabled && (
            <Route
              path="/materials-management"
              element={<MaterialsManagement />}
            />
          )}
          {(isWorkWithDataEnabled ||
            isWalkdownManagementEnabled ||
            isEnrichmentManagementEnabled ||
            isMaterialsManagementEnabled) && (
            <Route path="/monitor" element={<Monitor />} />
          )}
          <Route path="*" element={<NoPermission />} />
        </Routes>
      </div>
      <Notification />
    </div>
  );
};
