import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userRole: null,
    userRoleOptions: [],
    allowedUserActions: {
      all: true,
      wwd: true,
      wm: true,
      monitor: true,
      mm: true,
      em: true,
    },
  },
  reducers: {
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setUserRoleOptions: (state, action) => {
      state.userRoleOptions = action.payload;
    },
    setAllowedActionsByUser: (state, action) => {
      state.allowedUserActions = action.payload;
    },
  },
});

export const {
  setUserRole,
  setUserRoleOptions,
  setUserRoleLoading,
  setAllowedActionsByUser,
} = userSlice.actions;
export const selectUserRole = (state) => state.user.userRole;
export const getUserRoleOptions = (state) => state.user.userRoleOptions;
export const allowedUserActions = (state) => state.user.allowedUserActions;
export default userSlice.reducer;
