import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/global/userSlice";
import equipmentsFilterReducer from "./slices/equipmentsFilterSlice";
import wwdColumnsReducer from "./slices/work-with-data/columnsSlice";
import mmColumnsReducer from "./slices/materials-management/columnsSlice";
import wwdFilterReducer from "./slices/work-with-data/filterSlice";
import mmFilterReducer from "./slices/materials-management/filterSlice";
import manufacturerReducer from "./slices/work-with-data/manufacterSlice";
import characteristicsReducer from "./slices/work-with-data/characteristicsSlice";
import schedulesReducer from "./slices/walkdown-management/schedulesSlice";
import walkdownUsersReducer from "./slices/walkdown-management/walkdownUsersSlice";
import notAssignedTagsForWalkdownReducer from "./slices/walkdown-management/notAssignedTagsSlice";
import notAssignedTagsForEnrichmentReducer from "./slices/enrichment-management/notAssignedTagsSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    equipmentsFilter: equipmentsFilterReducer,
    wwdTableColumns: wwdColumnsReducer, // work with data
    mmTableColumns: mmColumnsReducer, // materials management
    filter: wwdFilterReducer,
    manufacturer: manufacturerReducer,
    characteristics: characteristicsReducer,
    mmfilter: mmFilterReducer,
    schedules: schedulesReducer,
    walkdownUsers: walkdownUsersReducer,
    notAssignedTagsForEnrichmentManagement: notAssignedTagsForEnrichmentReducer,
    notAssignedTagsForWalkdownManagement: notAssignedTagsForWalkdownReducer,
  },
});

export default store;
