import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useUserData } from "../hooks/useUserData";
import { CircularProgress } from "@mui/material";

import {
  setUserRole,
  setAllowedActionsByUser,
} from "../store/slices/global/userSlice";
import { setColumns } from "../store/slices/work-with-data/columnsSlice";
import { setColumns as mmSetColumns } from "../store/slices/materials-management/columnsSlice";

import {
  DEFAULT_WWD_COLUMNS,
  SME_USER_WWD_COLUMNS,
} from "../constants/work-with-data";
import { TABLE_COLUMNS as MM_TABLE_COLUMNS } from "../constants/materials-management";
import { USER_ROLE, USER_ROLE_BY_ID } from "../constants/environment";

import { Container, Header, Main } from "./layout";

export const App = () => {
  const dispatch = useDispatch();
  const { getUserRoleAndAllowedActions, loading } = useUserData();

  const setDefaultTableColumns = useCallback(
    (userRole) => {
      if (userRole === USER_ROLE.SME) {
        dispatch(setColumns(SME_USER_WWD_COLUMNS));
      } else {
        dispatch(setColumns(DEFAULT_WWD_COLUMNS));
      }
      dispatch(mmSetColumns(MM_TABLE_COLUMNS));
    },
    [dispatch]
  );

  useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      getUserRoleAndAllowedActions().then(({ user, actions }) => {
        if (isMounted && user) {
          dispatch(setUserRole(USER_ROLE_BY_ID[user.id]));
          setDefaultTableColumns(USER_ROLE_BY_ID[user.id]);
          dispatch(setAllowedActionsByUser(actions));
        } else {
          dispatch(setUserRole(USER_ROLE.RESTRICTED));
          setDefaultTableColumns(USER_ROLE.RESTRICTED);
          dispatch(
            setAllowedActionsByUser({
              wwd: false,
              wm: false,
              mm: false,
              monitor: false,
              all: false,
              em: false,
            })
          );
        }
      });
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [dispatch, getUserRoleAndAllowedActions, setDefaultTableColumns]);

  return (
    <Container>
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <CircularProgress size={50} />
        </div>
      ) : (
        <>
          <Header />
          <Main />
        </>
      )}
    </Container>
  );
};
