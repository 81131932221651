import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, TextField, Toolbar } from "@mui/material";

import ListIcon from "@mui/icons-material/List";

import {
  setFilterParams,
  getFilterParams,
  setCurrentPage,
} from "../../store/slices/work-with-data/filterSlice";
import { useWorkWithData } from "../../hooks/useWorkWithData";

import { CategoryButton } from "../common/CategoryButton";
import { CustomButton } from "../common/CustomButton";
import { ENTER_KEY } from "../../constants/global";
import { unitCodesGrouppedByArea, formatAreaCodes } from "../../utils";

export const WorkWithDataHeader = ({ toggleCategoriesDrawer }) => {
  const dispatch = useDispatch();
  const filterParams = useSelector(getFilterParams);
  const [search, setSearch] = useState("");
  const [area, setArea] = useState({ label: "", value: "" });
  const [unit, setUnit] = useState({ label: "", value: "" });
  const [areaCodes, setAreaCodes] = useState([]);
  const [unitCodes, setUnitCodes] = useState([]);
  const [unitCodesByArea, setUnitCodesByArea] = useState({});

  const { getAreaAndUnitCodes } = useWorkWithData();

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      getAreaAndUnitCodes().then((response) => {
        if (isMounted && response) {
          const unitCodesByArea = unitCodesGrouppedByArea(response);
          setUnitCodesByArea(unitCodesByArea);
          const areaCodes = formatAreaCodes(unitCodesByArea);
          setAreaCodes(areaCodes);
        }
      });
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [getAreaAndUnitCodes]);

  useEffect(() => {
    setSearch(filterParams.Search ? filterParams.Search : "");
    setArea(
      filterParams.AreaCode.label
        ? {
            label: filterParams.AreaCode.label,
            value: filterParams.AreaCode.value,
          }
        : { label: "", value: "" }
    );
    setUnit(
      filterParams.UnitCode.label
        ? {
            label: filterParams.UnitCode.label,
            value: filterParams.UnitCode.value,
          }
        : { label: "", value: "" }
    );
  }, [filterParams]);

  const handleAreaChange = (_event, option) => {
    if (option) {
      setArea({
        label: option.label,
        value: option.value,
      });
      setUnit({ label: "", value: "" });
      setUnitCodes(unitCodesByArea[option.label].unitCodes);
      dispatch(setCurrentPage(0));
    } else {
      setArea({ label: "", value: "" });
    }
  };

  const handleAreaInputChange = (_event, value) => {
    if (!value) {
      setUnit({ label: "", value: "" });
    }
  };

  const handleUnitChange = (_event, option) => {
    if (option) {
      setUnit({ label: option.label, value: option.value });
      dispatch(setCurrentPage(0));
    } else {
      setUnit({ label: "", value: "" });
    }
  };

  const getFilteredColumns = () => {
    let searchCondition = undefined;
    if (search) {
      searchCondition = [
        {
          ColumnName: "equipmenT_TAG",
          Operator: "Contains",
          Value: search,
        },
      ];
      if (filterParams.FilteredColums) {
        const allFilteredColums = [
          ...filterParams.FilteredColums,
          ...searchCondition,
        ];
        return Array.from(
          new Set(allFilteredColums.map((item) => JSON.stringify(item)))
        ).map((item) => JSON.parse(item));
      }
      return searchCondition;
    }
    return undefined;
  };

  const handleSearchClick = () => {
    dispatch(
      setFilterParams({
        ...filterParams,
        Search: search ?? undefined,
        AreaCode: area?.value
          ? { label: area.label, value: area.value }
          : { label: "", value: "" },
        UnitCode: unit?.value
          ? { label: unit.label, value: unit.value }
          : { label: "", value: "" },
        FilteredColums: getFilteredColumns() ?? filterParams.FilteredColums,
      })
    );
    dispatch(setCurrentPage(0));
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <Toolbar>
      <div className="flex items-center py-[24px] justify-between w-full gap-[16px]">
        <TextField
          sx={{ width: "100%", minWidth: "300px" }}
          label="Search equipment tags"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          inputProps={{
            "data-testid": "search-input",
            onKeyDown: (event) =>
              event.code === ENTER_KEY ? handleSearchClick() : null,
          }}
        />
        <Autocomplete
          disablePortal
          options={areaCodes || []}
          value={area?.label ?? null}
          onChange={handleAreaChange}
          noOptionsText="No area codes"
          onInputChange={handleAreaInputChange}
          sx={{ minWidth: "180px" }}
          renderInput={(params) => (
            <TextField
              label="Area code"
              {...params}
              inputProps={{ ...params.inputProps, "data-testid": "areacode" }}
            />
          )}
        />
        <Autocomplete
          disabled={!area?.label}
          disablePortal
          options={unitCodes || []}
          value={unit?.label ?? null}
          onChange={handleUnitChange}
          noOptionsText="No unit codes"
          sx={{ minWidth: "180px" }}
          renderInput={(params) => (
            <TextField
              label="Unit code"
              {...params}
              inputProps={{ ...params.inputProps, "data-testid": "unitcode" }}
            />
          )}
        />
        <CategoryButton
          onClick={toggleCategoriesDrawer(true)}
          sx={{ minWidth: "180px" }}
          startIcon={
            <ListIcon sx={{ width: "24px", height: "24px", color: "gray" }} />
          }
          data-testid="category"
        >
          Category
        </CategoryButton>
        <CustomButton
          sx={{ minWidth: "96px" }}
          onClick={handleSearchClick}
          data-testid="search-btn"
        >
          Search
        </CustomButton>
      </div>
    </Toolbar>
  );
};
