import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Button, ButtonGroup } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { isSchedulesListUpdated } from "../../store/slices/enrichment-management/schedulesSlice";
import { allowedUserActions } from "../../store/slices/global/userSlice";
import { useEnrichmentManagement } from "../../hooks/useEnrichmentManagement";

import { EnrichmentManagementSchedulesTable } from "./EnrichmentManagementSchedulesTable";
import { HeaderWithActions } from "../common/HeaderWithActions";

export const EnrichmentManagement = () => {
  const allowedActions = useSelector(allowedUserActions);
  const isUserActionEnabled = allowedActions.all || allowedActions.em;
  const isSchedulesUpdated = useSelector(isSchedulesListUpdated);
  const [activeButton, setActiveButton] = useState("schedules");
  const [openNewScheduleModal, setOpenNewScheduleModal] = useState(false);

  const [isSchedulesLoading, setIsSchedulesLoading] = useState(true);
  const [schedules, setSchedules] = useState([]);

  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [enrichmentUsers, setEnrichmentUsers] = useState([]);

  const { getEnrichmentSchedules, getEnrichmentUsers } =
    useEnrichmentManagement();

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      setIsSchedulesLoading(true);
      getEnrichmentSchedules()
        .then((response) => {
          if (isMounted && response) {
            setSchedules(response);
            setIsSchedulesLoading(false);
          }
        })
        .finally(() => {
          setIsSchedulesLoading(false);
        });
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [getEnrichmentSchedules, isSchedulesUpdated]);

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      setIsUsersLoading(true);
      getEnrichmentUsers()
        .then((response) => {
          if (isMounted && response) {
            const enrichmentUsers = response.map((user) => ({
              email: user.mail,
              firstName: user.givenName,
              lastName: user.surname,
              userPk: user.id,
            }));
            setEnrichmentUsers(enrichmentUsers);
            setIsUsersLoading(false);
          }
        })
        .finally(() => {
          setIsUsersLoading(false);
        });
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [getEnrichmentUsers]);

  const handleTabClick = (btn) => {
    setActiveButton(btn);
  };

  const handleAddNewSchedule = () => {
    setOpenNewScheduleModal(true);
  };

  const closeNewScheduleModal = () => {
    setOpenNewScheduleModal(false);
  };

  return (
    <div className="flex flex-col gap-6 w-full">
      <HeaderWithActions title="Enrichment Management" />
      <div className="flex items-center justify-between">
        <ButtonGroup variant="outlined" aria-label="Power Bi Dashboards">
          <Button
            sx={{
              width: "130px",
              backgroundColor: activeButton === "schedules" ? "#0066B214" : "",
            }}
            onClick={() => handleTabClick("schedules")}
            data-testid="schedules-btn"
          >
            Schedules
          </Button>
        </ButtonGroup>
        {isUserActionEnabled && (
          <>
            {activeButton === "schedules" && (
              <Button
                sx={{ fontSize: "14px" }}
                startIcon={<AddIcon />}
                onClick={handleAddNewSchedule}
                data-testid="add-new-schedule-btn"
              >
                Add new schedule
              </Button>
            )}
          </>
        )}
      </div>
      <div className="w=-full">
        {activeButton === "schedules" && (
          <EnrichmentManagementSchedulesTable
            rows={schedules}
            loading={isSchedulesLoading}
            openNewScheduleModal={openNewScheduleModal}
            closeNewScheduleModal={closeNewScheduleModal}
            enrichmentUsers={enrichmentUsers || []}
            isUserActionEnabled={isUserActionEnabled}
          />
        )}
      </div>
    </div>
  );
};
