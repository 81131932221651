import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MoreCommentsIcon from "@mui/icons-material/Chat";

import {
  Autocomplete,
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  TextField,
  Badge,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  allowedUserActions,
  selectUserRole,
} from "../../../store/slices/global/userSlice";
import {
  isCharacteristicsUpdated,
  isSpecificCharsUpdated,
  isWalkdownPicturesUpdated,
  selectedEquipmentTagId,
} from "../../../store/slices/work-with-data/characteristicsSlice";
import { useWorkWithData } from "../../../hooks/useWorkWithData";
import { getStatusesByUserRole } from "../../../utils";

import { AttributesTab, EquipmentDocuments, WalkdownPicturesTab } from ".";
import {
  EQUIPMENT_GENERAL_CHAR_NAME,
  ONLY_JDE_EQUIPMENT_CHARS,
  ORDERED_EQUIPMENT_CHARS,
} from "../../../constants/work-with-data";
import { STATUS_TYPE } from "../../../constants/global";
import { TransitionsModal } from "../../common/Modal";
import { CommentsHistory } from "../../common/CommentsHistory";

export const WorkWithDataDetails = ({ id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector(selectUserRole);
  const equipmentTagId = useSelector(selectedEquipmentTagId);
  const isEquipmentCharacteristicsUpdated = useSelector(
    isCharacteristicsUpdated
  );
  const isEquipmentPicturesUpdated = useSelector(isWalkdownPicturesUpdated);
  const allowedActions = useSelector(allowedUserActions);
  const isUserActionEnabled = allowedActions.all || allowedActions.wwd;
  const [activeButton, setActiveButton] = useState("attributes");

  const [statusEditMode, setStatusEditMode] = useState(false);
  const [assetNumberPhk, setAssetNumberPhk] = useState("");
  const [assetNumberBk, setAssetNumberBk] = useState("");
  const [equipmentChars, setEquipmentChars] = useState([]);
  const [initialEquipmentStatus, setInitialEquipmentStatus] = useState({
    value: "",
    label: "",
  });
  const [generalEquipmentStatus, setGeneralEquipmentStatus] = useState({
    value: "",
    label: "",
  });

  const [initialEquipmentComment, setInitialEquipmentComment] = useState("");
  const [generalEquipmentComment, setGeneralEquimentComment] = useState("");
  const [mainNewCommentValue, setMainNewCommentValue] = useState("");
  const [walkdownAssignTo, setWalkdownAssignTo] = useState("");
  const [enrichmentAssignTo, setEnrichmentAssignTo] = useState("");

  const [isEquipmentCharsLoading, setIsEquipmentCharsLoading] = useState(true);
  const [isCommentHistoryOpen, setIsCommentHistoryOpen] = useState(false);
  const [equipmentCommentsHistory, setEquipmentCommentsHistory] = useState([]);
  const [isEquipmentStatusLoading, setIsEquipmentStatusLoading] =
    useState(true);

  const [equipmentCommentsCount, setEquipmentCommentsCount] = useState(0);

  const { updateEquipmentGeneralStatusDetails } = useWorkWithData();

  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [equipmentStatuses, setEquipmentStatuses] = useState([]);
  const [attributeStatuses, setAttributeStatuses] = useState([]);

  const [imagesInfo, setImagesInfo] = useState(null);

  const [isImagesLoading, setIsImagesLoading] = useState(true);

  const {
    getUnifiedEquipmentTagCharacteristics,
    getWalkdownPictures,
    getCostCenterOptions,
    getEquipmentStatuses,
    getEquipmentGeneralStatusDetails,
    addNewEquipTagWalkdownCharComment,
  } = useWorkWithData();

  const fetchEquipmentStatusDetails = () => {
    getEquipmentGeneralStatusDetails(id)
      .then((response) => {
        if (response) {
          const generalStatus = {
            label: response.equipmentStatusName,
            value: response.equipmentStatusPhk,
          };
          setWalkdownAssignTo(response.walkdownAssignTo);
          setEnrichmentAssignTo(response.enrichmentAssignTo);
          setGeneralEquipmentStatus(generalStatus);
          setInitialEquipmentStatus(generalStatus);
          setGeneralEquimentComment(
            response.comments.find((comment) => comment.current)?.comment || ""
          );
          setInitialEquipmentComment(
            response.comments.find((comment) => comment.current)?.comment || ""
          );
          setEquipmentCommentsCount(response.comments.length);
          setAssetNumberPhk(response.equipmentAssetNumberPhk);
          setAssetNumberBk(response.equipmentAssetNumberBk);
          setEquipmentCommentsHistory(response.comments);
          setIsEquipmentStatusLoading(false);
        }
      })
      .finally(() => {
        setIsEquipmentStatusLoading(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      setIsEquipmentCharsLoading(true);
      getUnifiedEquipmentTagCharacteristics(id)
        .then((response) => {
          if (isMounted && response) {
            const formattedResponse = response.sort(
              (a, b) =>
                ORDERED_EQUIPMENT_CHARS.indexOf(a.jdeAttrName) -
                ORDERED_EQUIPMENT_CHARS.indexOf(b.jdeAttrName)
            );
            setEquipmentChars(formattedResponse);
            setIsEquipmentCharsLoading(false);
          }
        })
        .finally(() => {
          setIsEquipmentCharsLoading(false);
        });
    };
    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [
    getUnifiedEquipmentTagCharacteristics,
    id,
    isEquipmentCharacteristicsUpdated,
  ]);

  useEffect(() => {
    fetchEquipmentStatusDetails();
  }, []);

  const getFormattedStatusList = (response) => {
    const equipmentStatusList = response.filter(
      (item) => item.statusType === STATUS_TYPE.EQUIPMENT_STATUS
    );
    const attrStatusList = response.filter(
      (item) => item.statusType === STATUS_TYPE.ATTRIBUTE_STATUS
    );
    const equipmentStatuses = equipmentStatusList.map((item) => ({
      label: item.characteristicStatusName,
      value: item.characteristicStatusPhk,
    }));
    const attrStatuses = attrStatusList.map((item) => ({
      label: item.characteristicStatusName,
      value: item.characteristicStatusPhk,
    }));

    return {
      equipmentStatuses,
      attrStatuses,
    };
  };

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      getEquipmentStatuses().then((response) => {
        if (isMounted && response) {
          const { equipmentStatuses, attrStatuses } =
            getFormattedStatusList(response);
          setEquipmentStatuses(equipmentStatuses);
          setAttributeStatuses(attrStatuses);
        }
      });
    };

    fetchRows();

    return () => {
      isMounted = false;
    };
  }, [getEquipmentStatuses, role]);

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      getWalkdownPictures([equipmentTagId])
        .then((response) => {
          if (isMounted && response) {
            const images = response
              .map((item) =>
                item.equipmentImageInfo.map((imageInfo) => ({
                  ...imageInfo,
                  user: item.user,
                }))
              )
              .flat();
            setImagesInfo(images);
          }
        })
        .finally(() => {
          setIsImagesLoading(false);
        });
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [equipmentTagId, getWalkdownPictures, isEquipmentPicturesUpdated]);

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      getCostCenterOptions().then((response) => {
        if (isMounted && response) {
          setCostCenterOptions(response);
        }
      });
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [getCostCenterOptions]);

  const handleClick = (btn) => {
    setActiveButton(btn);
  };

  const handleEquipmentStatusChange = (_event, option) => {
    if (option) {
      setGeneralEquipmentStatus({ label: option.label, value: option.value });
    }
  };

  const handleMainCommentChange = (event) => {
    setGeneralEquimentComment(event.target.value);
    setMainNewCommentValue(event.target.value);
  };

  const getMainStatusAndCommentUpdates = () => {
    const updatedStatus = generalEquipmentStatus.label;
    const initialStatus = initialEquipmentStatus.label;
    const isMainStatusUpdated = initialStatus !== updatedStatus;
    const isMainCommentUpdated =
      initialEquipmentComment !== generalEquipmentComment;

    let commentMessage = "";
    if (isMainStatusUpdated) {
      if (isMainCommentUpdated && generalEquipmentComment.trim().length) {
        commentMessage = `Changed status from ${initialStatus} to ${updatedStatus} by leaving a comment: ${generalEquipmentComment}`;
      } else {
        commentMessage = `Changed status from ${initialStatus} to ${updatedStatus}`;
      }
    } else if (isMainCommentUpdated && generalEquipmentComment) {
      commentMessage = generalEquipmentComment;
    }

    return {
      isMainStatusUpdated,
      isMainCommentUpdated,
      commentMessage,
    };
  };

  const handleSaveMainStatus = () => {
    setStatusEditMode(false);
    setMainNewCommentValue("");
    const requests = [];
    const { isMainStatusUpdated, isMainCommentUpdated, commentMessage } =
      getMainStatusAndCommentUpdates();

    if (isMainStatusUpdated) {
      requests.push(
        updateEquipmentGeneralStatusDetails([
          {
            assetNumberPhk,
            statusPhk: generalEquipmentStatus.value,
          },
        ])
      );
    }

    if (
      (isMainCommentUpdated && commentMessage.trim().length) ||
      isMainStatusUpdated
    ) {
      requests.push(
        addNewEquipTagWalkdownCharComment([
          {
            commentHistoryPhk: assetNumberPhk,
            commentHistoryBk: assetNumberBk,
            comment: commentMessage,
          },
        ])
      );
    }

    Promise.all(requests).then((responses) => {
      const data = responses.some((response) => response.status === 200);
      if (data) {
        fetchEquipmentStatusDetails();
      }
    });
  };

  const handleCancelMainStatus = () => {
    setGeneralEquipmentStatus(initialEquipmentStatus);
    setGeneralEquimentComment(initialEquipmentComment);
    setMainNewCommentValue("");
    setStatusEditMode(false);
  };

  return (
    <div>
      <Button
        size="small"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/work-with-data")}
      >
        Back
      </Button>
      <div className="flex justify-between items-center font-fira text-xl not-italic font-bold leading-6">
        <div className="flex flex-col">
          <div>{id}</div>
          {isEquipmentStatusLoading ? null : (
            <div className="flex flex-col mb-2 text-black/[0.54] text-sm">
              <div>
                {`Assigned for enrichment to: ${
                  enrichmentAssignTo ? enrichmentAssignTo : "not assigned"
                }`}
              </div>
              <div>
                {`Assigned for field verification to: ${
                  walkdownAssignTo ? walkdownAssignTo : "not assigned"
                }`}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col items-end">
          <Paper className="flex p-2 gap-2" elevation={0} variant="outlined">
            <Autocomplete
              size="small"
              disablePortal
              options={equipmentStatuses}
              value={generalEquipmentStatus.label || ""}
              renderInput={(params) => (
                <TextField {...params} label="Equipment status" />
              )}
              onChange={(event, option) =>
                handleEquipmentStatusChange(event, option)
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ width: 300 }}
              disableClearable
              disabled={!statusEditMode || isEquipmentStatusLoading}
            />
            <div className="flex gap-2 items-center">
              <span className="text-sm leading-4 text-black/[0.87] font-roboto">
                {statusEditMode ? (
                  <TextField
                    className="w-full"
                    size="small"
                    label="Comments"
                    variant="outlined"
                    value={mainNewCommentValue}
                    placeholder={!mainNewCommentValue && "New comment"}
                    disabled={!statusEditMode || isEquipmentStatusLoading}
                    onChange={handleMainCommentChange}
                    style={{ width: 300 }}
                  />
                ) : (
                  <TextField
                    className="w-full"
                    size="small"
                    label="Comments"
                    variant="outlined"
                    value={initialEquipmentComment}
                    placeholder={!initialEquipmentComment && "No comment"}
                    disabled={!statusEditMode || isEquipmentStatusLoading}
                    style={{ width: 300 }}
                  />
                )}
              </span>
              <IconButton
                disabled={!equipmentCommentsCount}
                aria-label="comments"
                onClick={() => setIsCommentHistoryOpen(true)}
              >
                <Badge badgeContent={equipmentCommentsCount}>
                  <MoreCommentsIcon />
                </Badge>
              </IconButton>
            </div>
            {isUserActionEnabled && (
              <div className="flex items-center gap-4">
                {statusEditMode ? (
                  <>
                    <IconButton
                      color="error"
                      onClick={handleCancelMainStatus}
                      data-testid="cancel-btn"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      color="success"
                      onClick={handleSaveMainStatus}
                      data-testid="save-btn"
                    >
                      <CheckIcon fontSize="small" />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    data-testid="edit-btn"
                    color="primary"
                    onClick={() => setStatusEditMode(true)}
                    disabled={isEquipmentStatusLoading}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
              </div>
            )}
          </Paper>
        </div>
      </div>
      <div className="mt-6">
        <ButtonGroup variant="outlined" aria-label="Equipment details section">
          <Button
            sx={{
              backgroundColor: activeButton === "attributes" ? "#0066B214" : "",
            }}
            onClick={() => handleClick("attributes")}
            data-testid="attributes-btn"
          >
            Attributes
          </Button>
          <Button
            sx={{
              backgroundColor: activeButton === "documents" ? "#0066B214" : "",
            }}
            onClick={() => handleClick("documents")}
            data-testid="documents-btn"
          >
            Documents
          </Button>
          <Button
            sx={{
              backgroundColor:
                activeButton === "walkdown-pictures" ? "#0066B214" : "",
            }}
            onClick={() => handleClick("walkdown-pictures")}
            data-testid="walkdown-pictures-btn"
          >
            Walkdown pictures
          </Button>
        </ButtonGroup>
      </div>
      <div className="mt-6">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {activeButton === "attributes" && (
            <AttributesTab
              loading={isEquipmentCharsLoading}
              rows={equipmentChars}
              costCenterOptions={costCenterOptions}
              attributeStatuses={attributeStatuses}
            />
          )}
        </LocalizationProvider>
        {activeButton === "documents" && <EquipmentDocuments />}
        {activeButton === "walkdown-pictures" && (
          <WalkdownPicturesTab
            images={imagesInfo || []}
            loading={isImagesLoading}
          />
        )}
      </div>
      <TransitionsModal
        open={isCommentHistoryOpen}
        handleClose={() => setIsCommentHistoryOpen(false)}
      >
        <CommentsHistory comments={equipmentCommentsHistory} />
      </TransitionsModal>
    </div>
  );
};
